import Store from "./../Application/Store"
import Icon from "./../Service/Icon"
import Preloader from "./../Service/Preloader"
import Languages from "./../Service/Languages"
import Code from "./../Service/Code"

export default class Application
{
    private store: any

    async run(): Promise<void>
    {
        const code: Code = new Code()
        code.run()

        const icon: Icon = new Icon()
        icon.run()
        icon.watch()

        const preloader: Preloader = new Preloader()
        preloader.watch()

        const languages: Languages = new Languages()
        languages.run()

        const store: Store = new Store()
        this.store = await store.get()
    }
}
