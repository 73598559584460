export default class Preloader
{
	preloaders = document.querySelectorAll('[data-s~="Preloader"]')

    config
    observer

	constructor()
	{
		this.config = {
			rootMargin: "0px",
			threshold: 0.25
		}

		this.observer = new IntersectionObserver(this.hide, this.config)
	}

	hide(es, observer)
	{
		es.forEach((e) => {
			if (e.isIntersecting) {
				setTimeout(() => {
					e.target.style.opacity = 0
					setTimeout(() => {
						e.target.remove()
						observer.unobserve(e.target)
					}, 500)
				}, Math.random() * (2000 - 1000) + 100)
			}
		})
	}

	watch()
	{
        this.preloaders.forEach(e => {
			this.observer.observe(e)
		})
	}
}
