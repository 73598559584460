// import { createHighlighter } from "shiki"
import * as monaco from "monaco-editor"

export default class Code
{
    private is: NodeListOf<Element>

	constructor()
	{
        this.is = document.querySelectorAll('[data-s~="Preloader"]')
    }

    // private async setup(): Promise<void>
    // {
    //     const highlighter = await createHighlighter({
    //         themes: ["rose-pine-dawn"],
    //         langs: ["json"]
    //     })

    //     const code = highlighter.codeToHtml('const a = 1', {
    //         lang: "json",
    //         theme: "rose-pine-dawn",
    //     })





    //     const editor = document.getElementById("editor") as HTMLElement | null

    //     if (!editor) {
    //         console.error("Element #editor nie został znaleziony!")
    //         return
    //     }

    //     function updateHighlight() {
    //         const code = editor.innerText
    //         editor.innerHTML = highlighter.codeToHtml(code, { lang: "json", theme: "default" })
    //         editor.setAttribute("contenteditable", "true")
    //     }

    //     editor.addEventListener("input", updateHighlight)
    //     updateHighlight()
    // }


    public run(): void
    {

    }
}
