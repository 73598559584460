export default class Preloader
{
	private is: NodeListOf<Element>

	constructor()
	{
        this.is = document.querySelectorAll('[data-s~="Preloader"]')
    }

	private hide = (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void =>
	{
		entries.forEach((entry: IntersectionObserverEntry) => {
			if (entry.isIntersecting) {
				setTimeout(() => {
					if (entry.target instanceof HTMLElement) {
						entry.target.style.opacity = "0"

						setTimeout(() => {
							entry.target.remove()
							observer.unobserve(entry.target)
						}, 500)
					}
				}, Math.random() * (2000 - 1000) + 100)
			}
		})
	}

	private observe(elements: NodeListOf<Element>, callback: IntersectionObserverCallback, config: IntersectionObserverInit): void
    {
        const intersectionObserver = new IntersectionObserver(callback, config)

        elements.forEach((element: Element) => {
            intersectionObserver.observe(element)
		})
    }

	public watch(): void
	{
		this.observe(this.is, this.hide, {
            rootMargin: "0px",
            threshold: 0
        })
	}
}
